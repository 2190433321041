<template>
  <div class="grey-background">
    <v-container v-if="isCameraOpen" v-show="!isLoading" :class="{ 'flash' : isShotPhoto }">
      <v-row>
        <v-col cols="12" align="center">
          <h4> {{$t('SelfiewithyourIDCard')}}</h4> 
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-0">
          <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
          <div class="camera">
            <video v-show="!isPhotoTaken" ref="camera" autoplay class="feed"></video>
          </div>
        </v-col>
      </v-row>
      <v-container v-if="isCameraOpen && !isLoading && !isPhotoTaken" class="camera-shoot" style="width:100%" align="center">
        <v-row justify="center">
          <v-col cols="auto mt-n5">
            <v-img 
              class=""
              max-width="50px"
              max-height="50px"  
              :src="require('../../assets/kyc/buttonCamera.svg')"
              @click="takePhoto()">
            </v-img>
          </v-col>
          <v-col cols="auto mt-n5" style="right: 5%;position: absolute;">
            <v-img
              class="mt-2"
              max-width="30px"
              max-height="30px"  
              :src="require('../../assets/kyc/camera-back.svg')"
              @click="changeCamera()">
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <div v-show="isPhotoTaken" class="white-background">
      <notification-popup 
        :dialog="notifFailed"
        :contentMsg="$t('failed')"
        :headerMsg="$t('FailedSubmit')"
        nameBtnNo="False"
        :nameBtnYes="$t('TryAgain')"
        typeModal="transaction"
        disableBtnNo="true"
        :onHandlerYes="btnFailed"/>


      <canvas id="photoTaken" ref="canvas"></canvas>
      <div align=center>
        <v-btn style="font-size: 14px;text-transform: capitalize;font-weight: 500;color: #2CD196;letter-spacing: 0.04em;"
          @click="takePhoto"
          small
          dark
          text>
          {{$t('RepeatPhoto')}}
        </v-btn>
      </div>
      <v-container v-if="isPhotoTaken && isCameraOpen" class="pt-0 camera-shoot" style="width:100%" align="center">
        <v-row justify="center">
          <v-col cols="8">
            <v-btn
              class="button-information"
              @click="Confirmation()"
              color="primary"
              normal
              dark
              width="100%"
              rounded>{{$t('Submit')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import NotificationPopup from "@/components/NotificationPopup.vue";
  export default {
    name: 'CameraOnSelfieIdentityCard',
    components: {NotificationPopup},
    data() {
      return {
        isCameraOpen: true,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        backCam : false,
        frontCam : true,
        link: '#',
        photo_selfie : this.$store.state.cdn.photo_selfie_id,
        dataResponse:"",
        notifFailed :false
      }
    },
    mounted(){
      this.isCameraOpen = true;
      this.frontCamera();
    },
    methods:{
      changeCamera() {
        if(this.backCam == true){
          this.backCam = false;
          this.frontCamera()
        } else {
          this.frontCam = false
          this.backCamera()
        }
      },
      backCamera(){
        this.isLoading = true;
        const constraints = (window.constraints = {
          audio: false,
          video:{facingMode:{exact:"environment"}}
        });
        
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
            alert("May the browser didn't support or there is some errors.");
          });
          this.backCam=true
      },
      frontCamera(){
        this.isLoading = true;
          const constraints = (window.constraints = {
            audio: false,
            video : {facingMode:"user"}
          });

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
            alert("May the browser didn't support or there is some errors.");
          });
          this.frontCam=true;
      },
      b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 100;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);

          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }

          var byteArray = new Uint8Array(byteNumbers);
      
          byteArrays.push(byteArray);
        }
       var blob = new Blob(byteArrays, {type: contentType});
        return blob;
      },
      Confirmation(){
        var canvas = document.getElementById("photoTaken");
        var img    = canvas.toDataURL("image/jpeg");
        var block = img.split(";");
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        /* get the real base64 content of the file */ 
        var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

        // Convert it to a blob to upload
        var blob = this.b64toBlob(realData, contentType);

        console.log('Encoded Binary File String:', blob);

        let payload = {
          file: blob,
          filename: this.$store.state.auth.userData.user_name + "_selfie_id_card.jpg",
        }
        console.log(payload)
        
        this.$store
          .dispatch("cdn/uploadPhotoSelfieID", payload)
          .then((response) => {
            if (response.status == true) {
              this.dataResponse =  response.photo
            }
          }).then(() =>{
            if (this.dataResponse == ""){
              this.notifFailed = true
            }else{
            let payloadKyc = {
              photo_passenger_id_card: this.dataResponse,
            }

            this.$store
              .dispatch("passenger/selfieIdCardKyc", payloadKyc)
              .then((resp) => {
                if (resp.status == true) {
                  this.$router.push({
                    name: 'CameraOnSelfiePremium',
                  })
                }
              })
              .catch((err) => {
                console.log(err);
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      createCameraElement() {
        this.isLoading = true;
        
        const constraints = (window.constraints = {
          audio: false,
          video: true
        });


        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error)
            alert("May the browser didn't support or there is some errors.");
          });
      },
      stopCameraStream() {
        let tracks = this.$refs.camera.srcObject.getTracks();

        tracks.forEach(track => {
          track.stop();
        });
      },
      takePhoto() {
        if(!this.isPhotoTaken) {
          this.isShotPhoto = true;

          const FLASH_TIMEOUT = 50;

          setTimeout(() => {
            this.isShotPhoto = false;
          }, FLASH_TIMEOUT);
        }
        
        this.isPhotoTaken = !this.isPhotoTaken;
        const context = this.$refs.canvas.getContext('2d');
        context.canvas.width = window.innerWidth;
        context.canvas.height = 200;
        context.drawImage(this.$refs.camera, 0, 0, window.innerWidth, 200);
        // console.log("data",context.drawImage(this.$refs.camera, 0, 0, 450, 337.5))
      },
      btnFailed(){
        this.notifFailed = false;
      },
    },
  }
</script>

<style lang="scss" scoped>
.camera {
  width: 100%;
  
  .feed {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .camera-stream {
    width: 100%;
    max-height: 70%;
    border-radius: 50%;
    border: 5px solid #FFFFFF;
  }

  .grey-background {
    position: flex;
    display: block;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background-color: #646464;
    color: #ffffff;
  }

  .white-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background: #ffffff;
  }
  
  
  .camera-shoot {
    position: fixed;
    bottom: 0;
    width: auto;
    text-align: center;
    margin: 1rem 0; 
    cursor: pointer;
  }
  
  .camera-img-capture{
    margin-top: -30px;  
  }

 .button-information{
    width: 100%;
    color:#4BB14E;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .camera-stream {
    width: 100%;
    max-height: 60%;
    border-radius: 50%;
    border: 5px solid #FFFFFF;
  }

  .grey-background {
    position: flex;
    display: block;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background-color: #646464;
    color: #ffffff;
  }

  .white-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background: #ffffff;
  }
  
  
  .camera-shoot {
    position: fixed;
    bottom: 0;
    width: auto;
    text-align: center;
    margin: 1rem 0; 
    cursor: pointer;
  }
  
  .camera-img-capture{
    margin-top: -30px;  

  }

 .button-information{
    width: 100%;
    color:#4BB14E;
  }
}
</style>